@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@300&family=Inter:wght@200&family=Josefin+Sans:wght@100;200;300;400;500;700&family=M+PLUS+1:wght@300&family=Mukta:wght@200&family=PT+Sans:wght@400;700&family=Poppins:wght@400;700&family=Raleway:ital,wght@0,100;0,200;0,300;0,900;1,100&family=Roboto:wght@300&family=Shippori+Antique&family=Ubuntu:wght@300&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: "Inter";
}

.swiper .swiper-button-next {
  color: rgb(64, 224, 208);
}

.swiper .swiper-button-prev {
  color: rgb(64, 224, 208);
}

.swiper .swiper-pagination-bullet {
  background-color: rgb(64, 224, 208);
}

.card {
  box-sizing: border-box;
  width: 300px;
  height: 250px;
  background: rgba(217, 217, 217, 0.58);
  border: 1px solid white;
  box-shadow: 12px 17px 51px rgba(0, 0, 0, 0.22);
  backdrop-filter: blur(6px);
  border-radius: 17px;
  cursor: pointer;
  transition: all 0.5s;
  display: inline-block;
  user-select: none;
}

.card:hover {
  border: 1px solid black;
  transform: scale(1.05);
}

.card:active {
  transform: scale(0.95) rotateZ(1.7deg);
}
